@import '../../../../scss/theme-bootstrap';

.cart-item {
  @include pie-clearfix;
  display: block;
  text-align: left;
  color: $color-black;
  padding: iem 0;
  &:hover {
    color: $color-black;
  }
  // Layout:
  &__img-container,
  &__info,
  &__cart-details {
    float: left;
    display: block;
  }
  &__img {
    min-height: 1px;
    margin-right: 4.25%;
    width: 100%;
    &-container {
      width: 16.5%;
      min-height: 1px;
    }
  }
  &__info {
    width: 52.75%;
    margin-right: 4.25%;
    text-align: left;
    &__header {
      min-height: 50px;
    }
  }
  &__cart-details {
    width: 22.25%;
    text-align: right;
  }

  // Styles:
  &__subheading {
    font-size: 0.75em;
  }
  &__sku-label,
  &__qty {
    text-transform: uppercase;
  }
  &__qty {
    margin: 0 0 1em;
  }
  &__price {
    font-size: 1.2em;
    letter-spacing: 0;
    color: $color-black;
  }
  &__shade {
    @include pie-clearfix;
    margin: 0;
    &__swatch {
      width: 21px;
      height: 14px;
      margin-right: 10px;
      float: left;
    }
    &__name {
      display: inline-block;
      margin: 0;
      float: left;
    }
  }
  &__engraving {
    border-bottom: solid 1px $color-light-gray;
    padding-bottom: 10px;
    &-preview {
      background: $gray;
      padding: 1em;
      font-size: $base-font-size;
      font-family: $font--lamer-headline;
      font-weight: normal;
      text-transform: none;
      margin: 0;
    }
  }
}
